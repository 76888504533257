<template>
    <div class="compass-rose">
        <svg width="0" height="0">
            <defs>
                <linearGradient id="mygradient" gradientTransform="rotate(180 0.5 0.5)">
                    <stop offset="5%" :class="`start-color${center ? ' start-color--active' : ''}`" />
                    <stop offset="95%" :class="`end-color${center ? ' end-color--active' : ''}`" />
                </linearGradient>
            </defs>
        </svg>
        <svg :class="`rose`" :style="`transform:rotate(${rotation}deg)`" fill="none" height="428" viewBox="0 0 428 428"
            width="428" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <clipPath id="a">
                <path d="m0 0h427.143v427.143h-427.143z" />
            </clipPath>

            <g clip-path="url(#a)" fill="#fff">
                <path id="rose"
                    d="m320.357 320.357-48.367-74.136 155.153-32.65-155.153-32.649 48.367-74.136-74.136 48.367-32.65-155.153-32.649 155.153-74.136-48.367 48.367 74.136-155.153 32.649 155.153 32.65-48.367 74.136 74.136-48.367 32.649 155.153 32.65-155.153zm-106.786-71.775c-19.36 0-35.01-15.65-35.01-35.011 0-19.36 15.65-35.01 35.01-35.01 19.361 0 35.011 15.65 35.011 35.01 0 19.361-15.65 35.011-35.011 35.011z" />
                <path id="top-left" :class="`arc ${topLeft ? 'arc--active' : ''}`"
                    d="m192.656 34.0663 2.9-13.8963c-92.889 8.5672-166.8228 82.501-175.39 175.39l13.8963-2.9c9.579-82.974 75.6197-149.0147 158.5937-158.5937z" />
                <path id="top-right" :class="`arc ${topRight ? 'arc--active' : ''}`"
                    d="m393.074 192.66 13.896 2.9c-8.567-92.889-82.501-166.8903-175.39-175.39l2.901 13.8963c82.973 9.579 149.014 75.6197 158.593 158.5937z" />
                <path id="bottom-left" :class="`arc ${bottomLeft ? 'arc--active' : ''}`"
                    d="m34.0623 234.483-13.8963-2.9c8.5672 92.889 82.501 166.89 175.39 175.39l-2.9-13.897c-82.974-9.579-149.0147-75.62-158.5937-158.593z" />
                <path id="bottom-right" :class="`arc ${bottomRight ? 'arc--active' : ''}`"
                    d="m234.481 393.076-2.901 13.897c92.889-8.567 166.891-82.501 175.39-175.39l-13.896 2.9c-9.579 82.973-75.62 149.014-158.593 158.593z" />
                <!-- <circle cx="213.569" cy="213.571" r="16.0178" /> -->
            </g>
        </svg>
        <slot></slot>

    </div>
</template>

<script>
export default {
    name: 'CompassRose',
    data() {
        return {
            dir: 'center'
        }
    },
    props: ['topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'rotation', 'center'],
}
</script>


<style scoped lang="scss">
.arc {
    fill: rgba(255, 255, 255, 1);
    opacity: 0;
    transition: fill 200 ease-in, opacity 200 ease-in;

    &--active {
        opacity: 1;
        fill: #153F67;
    }

    animation-iteration-count: 1;
    animation: fadeIn 1s forwards;

}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.35;
    }
}


@keyframes fadeInLong {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#rose {
    fill: url(#mygradient);
    opacity: 0;
    animation-iteration-count: 1;
    animation: fadeInLong 400ms forwards;
    animation-delay: 500ms;
}


.rose {
    transition: transform 400ms ease-in;

}

.north {

    #top-left {
        fill: blue;
    }

    #top-right {
        fill: blue;
    }

    #rose {
        transform: rotate(90deg);
    }

    #mygradient {

        .start-color {
            stop-color: rgba(255, 255, 255, 0.1);
        }

        .end-color {
            // stop-color: rbga(255, 255, 255, 0.1);
            stop-color: rgba(255, 255, 255, 1);
        }
    }
}

.east {
    #top-right {
        fill: blue;
    }

    #bottom-right {
        fill: blue;
    }

    #mygradient {
        .start-color {
            stop-color: rgba(255, 255, 255, 0.1);
        }

        .end-color {
            // stop-color: rbga(255, 255, 255, 0.1);
            stop-color: rgba(255, 255, 255, 1);
        }
    }
}

.south {
    #bottom-left {
        fill: blue;
    }

    #bottom-right {
        fill: blue;
    }

    #mygradient {
        transform: rotateX(270deg);

        .start-color {
            stop-color: rgba(0, 0, 255, 1);
        }

        .end-color {
            stop-color: rgba(255, 2, 2, 0.1);
        }
    }
}

.west {
    #top-left {
        fill: blue;
    }

    #bottom-left {
        fill: blue;
    }

    #mygradient {
        .start-color {
            stop-color: rgba(255, 255, 255, 1);
        }

        .end-color {
            stop-color: rgba(255, 255, 255, 0.1);
        }
    }
}

#mygradient {

    .start-color {
        transition: stop-color 200ms ease-in;
        stop-color: rgba(255, 255, 255, 1);

        &--active {
            stop-color: rgba(255, 255, 255, 1);
        }
    }

    .end-color {
        transition: fill 200ms ease-in;
        transition: stop-color 200ms ease-in;
        stop-color: rgba(255, 255, 255, 0.01);

        &--active {
            stop-color: rgba(255, 255, 255, 1);
        }
    }

}
</style>