<template>
    <div>
        <button class="dropdown-button" @click="toggleDropdown">{{ label }}</button>
        <div ref="dropDown" :class="dropDownClass">
            <button class="dropdown-close-button" @click="toggleDropdown">
                <img src="plus-blue.svg" />
            </button>
            <ul>
                <slot></slot>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropDown',
    props: ['label', 'getter', 'setter', 'collision'],
    data() {
        return {
            open: false
        }
    },
    computed: {
        dropDownClass() {
            return this.$store.getters[this.getter] ? 'dropdown-content' : 'dropdown-content closed'
        }
    },
    methods: {
        toggleDropdown() {
            const open = !this.$store.getters[this.getter]
            this.$store.dispatch(this.setter, open)
            this.$store.dispatch(this.collision, false)
        }
    }
}
</script>

<style></style>