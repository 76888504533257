<template>
    <div class="top-nagivation">
        <div class="top-navigation-left">
            <transition name="fade">
                <MenuNavigation></MenuNavigation>
            </transition>
            <transition name="fade">
                <ToursNavigation></ToursNavigation>
            </transition>


        </div>
        <div class="top-navigation-center">
            <!-- <span class="color-primary thai">บ้านฮอลลดา</span> -->
            <img class="baan-logo" :src="'baan-hollanda_logo.svg'" />
            <!-- <span>BAAN HOLLANDA</span> -->
        </div>
        <div class="top-navigation-right">
        </div>
    </div>
</template>

<script>
import MenuNavigation from './MenuNavigation.vue';
import ToursNavigation from './ToursNavigation.vue';

export default {
    name: 'TopNavigation',
    components: {
        ToursNavigation,
        MenuNavigation,
    }
}

</script>


<style lang="scss" scoped>
img {
    margin: 0 8px
}

.top-nagivation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: #111111;
}

.navigation-link {
    margin-right: 24px;
    text-decoration: none;
    color: #ffffff;
}

.top-navigation-left {
    width: 25%;
    padding-left: 20px;
    text-align: left;
    display: flex;

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.top-navigation-center {
    display: flex;
    justify-content: space-around;
    width: 25%;
    align-items: center;

    span {
        width: calc(100% / 2);
        text-align: left;
    }

    .thai {
        text-align: right;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        ;
    }
}

.top-navigation-right {
    padding-right: 20px;
    width: 25%;
    text-align: right;

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.color-primary {
    color: var(--color-primary);
}

.baan-logo {}
</style>