export const homeConnections = [
    { name: 'north', arrow: 'up', page: 'north', transition: 'slide-down' },
    { name: 'east', arrow: 'right', page: 'east', transition: 'slide-left' },
    { name: 'south', arrow: 'down', page: 'south', transition: 'slide-up' },
    { name: 'west', arrow: 'left', page: 'west', transition: 'slide-right' },
]

export const northConnections = [
    { name: 'center', arrow: 'down', page: '/', transition: 'slide-up' }
]

export const eastConnections = [
    { name: 'center', arrow: 'left', page: '/', transition: 'slide-right' }
]

export const southConnections = [
    { name: 'center', arrow: 'up', page: '/', transition: 'slide-down' }
]

export const westConnections = [
    { name: 'center', arrow: 'right', page: '/', transition: 'slide-left' }
]



