<template>
    <div class="dropdown">
        <Dropdown label="menu" setter="setShowTopMenu" getter="showTopMenu" collision="setShowTourMenu">
            <li class="dropdown-item" v-for="route in routes" :key="route.name">
                <router-link :to="route.path">
                    {{ route.name }}
                </router-link>
            </li>
        </Dropdown>
    </div>
</template>

<script>
import Dropdown from './Dropdown.vue'
export default {
    name: 'MenuNavigation',
    components: {
        Dropdown
    },
    data() {
        return {
            open: false,
            routes: [
                { name: "Stories of trade", path: "/" },
                { name: "People", path: "north" },
                { name: "Correspondence", path: "west" },
                { name: "Goods & Trade", path: "south" },
                { name: "Baan Hollanda", path: "east" }
            ]
        }
    },

    methods: {
        openTour() {
            if (this.$store.getters.currentMicrio) {
                // const currentMicrio = this.$store.getters.currentMicrio
                // currentMicrio.state.tour.set(currentMicrio.$current.$data.markerTours[i])
                // this.toggleDropdown()
                // this.$store.dispatch('setShowArrows', false)
                // this.$store.this.$store.dispatch('setShowArrows', false)
            }
        },
        // toggleDropdown() {
        // this.$refs.dropDown.classList.toggle('closed')
        // this.$store.dispatch('setShowArrows', this.$refs.dropDown.classList.contains('closed'))

        // }
    }
}
</script>


<style lang="scss">
.dropdown {}

.dropdown-content {
    width: 100vw;
    background-color: rgba(245, 243, 235, 0.9);
    position: absolute;
    max-height: 100vh;
    transition: max-height 400ms ease-in;
    z-index: 10;
    list-style: none;
    padding-left: unset;
    left: 0;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 900px;
    overflow: hidden;
    transition: max-height 400ms, padding-top 400ms;

    >ul {
        padding: 40px
    }
}


.dropdown-item {
    position: relative;
    cursor: pointer;
    list-style: none;
    text-align: center;


    &:hover {
        color: var(--color-primary);
    }

    >a {
        text-decoration: none;
        font-family: noto sans, Helvetica, Arial, sans-serif;
        color: #11111199;
        font-size: 40px;
        line-height: 96px;

        &:hover {
            color: #153F67;
        }
    }
}

.dropdown-button {
    border: none;
    outline: none;
    color: #FFFFFF;
    background-color: transparent;
    cursor: pointer;
    font-family: noto sans;
    font-size: 16px;
    max-height: 400px;

    &:hover {
        color: var(--color-primary);
    }
}

.dropdown-close-button {
    position: absolute;
    right: 24px;
    top: 24px;
    background: transparent;
    border: none;
    transform: rotate(45deg);
    width: 40px;
    height: 40px;
    cursor: pointer;

    >img {
        height: 100%;
        width: 100%;
    }
}

.closed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
}

// .open {
//     animation: 400ms 1 open;
// }

@keyframes open {
    from {
        height: 0;
    }

    to {
        height: 400px;
    }
}

@keyframes close {
    from {
        height: 400px;
    }

    to {
        height: 0;
        display: none;
    }
}
</style>