<template>
    <div v-if="$store.getters.micrioTours.length > 0" class="dropdown">
        <Dropdown label="tours" setter="setShowTourMenu" getter="showTourMenu" collision="setShowTopMenu">
            <li class="dropdown-item" v-for="tour, i in $store.getters.micrioTours" :key="tour.title"
                @click="() => { openTour(i) }">
                {{ tour.title }}
            </li>
        </Dropdown>
    </div>
</template>

<script>
import Dropdown from './Dropdown.vue'
export default {
    name: 'TourNavigation',
    components: {
        Dropdown
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        openTour(i) {
            if (this.$store.getters.currentMicrio) {
                const currentMicrio = this.$store.getters.currentMicrio
                currentMicrio.state.tour.set(currentMicrio.$current.$data.markerTours[i])
                this.$store.dispatch('setShowArrows', false)
                this.$store.dispatch('setShowTourMenu', false)

            }
        },

    }
}
</script>


<style lang="scss">
.dropdown {}

.dropdown-content {
    width: 100vw;
    background-color: rgba(245, 243, 235, 0.9);
    position: absolute;
    max-height: 100vh;
    transition: max-height 400ms ease-in;
    z-index: 10;
    list-style: none;
    padding-left: unset;
    left: 0;
    top: 50px;
    display: flex;
    height: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    transition: max-height 400ms, padding-top 400ms;

    >ul {
        padding: 40px
    }
}


.dropdown-item {
    position: relative;
    cursor: pointer;
    list-style: none;

    &:hover {
        color: var(--color-primary);
    }

    font-family: noto sans,
    Helvetica,
    Arial,
    sans-serif;
    color: #11111199;
    font-size: 40px;
    line-height: 96px;
}

.dropdown-button {
    border: none;
    outline: none;
    color: #FFFFFF;
    background-color: transparent;
    cursor: pointer;
    font-family: noto sans;
    font-size: 16px;
    max-height: 400px;

    &:hover {
        color: var(--color-primary);
    }
}

.dropdown-close-button {
    position: absolute;
    right: 24px;
    top: 24px;
    background: transparent;
    border: none;
    transform: rotate(45deg);
    width: 40px;
    height: 40px;
    cursor: pointer;

    >img {
        height: 100%;
        width: 100%;
    }
}

.closed {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
}

// .open {
//     animation: 400ms 1 open;
// }

@keyframes open {
    from {
        height: 0;
    }

    to {
        height: 400px;
    }
}

@keyframes close {
    from {
        height: 400px;
    }

    to {
        height: 0;
        display: none;
    }
}
</style>