<template>
    <div class="content-page">
        <button @click="click" class="close-button">
            <img src="plus-blue.svg" />
        </button>
        <div class="content-inner">
            <div class="content">
                <h2>Dutch Trading Post: Heritage Network</h2>
                <img src="houses.svg" />
                <p>​
                    ​In the 17th and 18th centuries, the VOC operated a large trading network connecting many cities
                    around Asia, as well as
                    the Netherlands and other regions. This network brings our institutions together in order to
                    re-establish some of these
                    connections.<br /><br />Through this network we aim to share knowledge and research on our
                    shared
                    cultural heritage and
                    to cooperate on future historical research initiatives. The network also functions as a platform
                    to
                    increase cultural,
                    educational and economic exchange.<br />
                </p>
                <h4>Heritage in each member city</h4>

                <p>
                    All our member institutions are involved in the management of VOC heritage in their respective
                    cities.
                    We invite you to
                    expand your world. Follow up on the exploration you started in Ayutthaya by visiting another
                    member
                    city&rsquo;s
                    platform.
                </p>
                <h4>Member cities:</h4>
                <a href="/" class="link">Ambon (Indonesia)</a> Ambon City Government<br />
                <a href="/" class="link">Anping (Taiwan)</a> Taijiang National Park Headquarters<br />
                <a href="/" class="link">Ayutthaya (Thailand)</a> Baan Hollanda Foundation<br />
                <a href="/" class="link"> Banda (Indonesia)</a> Banda Heritage Foundation<br />
                <a href="/" class="link">Galle (Sri Lanka)</a> Galle Heritage Foundation<br />
                <a href="/" class="link"> Hirado (Japan)</a> Hirado Dutch Trading Post<br />
                <a href="/" class="link">Jakarta (Indonesia)</a> Indonesian Documentation Centre of
                Architecture<br />
                <a href="/" class="link">Melaka (Malaysia)</a> Melaka World Heritage Office<br />
                <a href="/" class="link">Muziris (India)</a> Muziris Heritage Project<br />
                <a href="/" class="link">Nagasaki (Japan)</a> Nagasaki City Dejima Restoration Office<br />
                <a href="/" class="link">Penghu (Taiwan)</a> National Museum of Taiwan History<br />
                <a href="/" class="link">Tainan (Taiwan)</a> Tainan Cultural Association<br />
                <a href="/" class="link">Ternate (Indonesia)</a> Ternate Heritage Society<br />
                <br />
            </div>
        </div>

    </div>
</template>

<script>
export default {

    name: "ContentView",
    mounted() {
    },
    methods: {
        click() {
            const path = this.$route.matched[0].path !== '' ? this.$route.matched[0].name : '/'
            this.$router.push(path)
        }
    }
}
</script>
 
<style lang="scss" scoped>
.content-page {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 14;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px 0 0 0;
    background-color: var(--color-primary);



}

.content-inner {
    height: calc(100vh - 100px);
    width: calc(100vw - 100px);
    background: rgba(245, 243, 235, 1);
    padding-top: 20px;
    overflow: scroll;
    text-align: left;
    display: flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    z-index: 10;
}

.close-button {
    top: 22px;
    right: 22px;
    position: absolute;
    transform: rotate(45deg);
    border: none;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
}

.content {
    width: 50%;

    >h2 {
        text-align: center;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
    }

    >h4 {
        margin-bottom: 0px;
    }
}

.link {
    color: var(--color-primary)
}
</style>