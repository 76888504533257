import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    micrioList: [],
    currentMicrio: null,
    nextMicrio: null,
    showMenu: false,
    showArrows: true,
    tourActive: false,
    startOverlayActive: true,
    micrioTours: [],
    showTopMenu: false,
    showTourMenu: false
  },
  getters: {
    showMenu: state => {
      return state.showMenu
    },
    showTopMenu: state => {
      return state.showTopMenu
    },
    showTourMenu: state => {
      return state.showTourMenu
    },
    showArrows: state => {
      return state.showArrows
    },
    micrioList: state => {
      return state.micrioList
    },
    currentMicrio: state => {
      return state.currentMicrio
    },
    nextMicrio: state => {
      return state.currentMicrio
    },
    micrioTours: state => {
      return state.micrioTours
    },
    tourActive: state => {
      return state.tourActive
    },
    startOverlayActive: state => {
      return state.startOverlayActive
    }
  },
  mutations: {
    setMicrioList(state, micrios) {
      state.allMicrios = micrios;
    },
    setCurrentMicrio(state, currentMicrio) {
      state.currentMicrio = currentMicrio
    },
    setNextMicrio(state, nextMicrio) {
      state.currentMicrio = nextMicrio
    },
    setShowMenu(state, showMenu) {
      state.showMenu = showMenu
    },
    setShowArrows(state, showArrows) {
      state.showArrows = showArrows
    },
    setMicrioTours(state, micrioTours) {
      state.micrioTours = micrioTours
    },
    setTourActive(state, tourActive) {
      state.tourActive = tourActive
    },
    setShowTourMenu(state, open) {
      state.showTourMenu = open
    },
    setShowTopMenu(state, open) {
      state.showTopMenu = open
    },
    setShowStartOverlay(state, startOverlayActive) {
      state.startOverlayActive = startOverlayActive
    }
  },
  actions: {
    setMicrioList(context, micrios) {
      context.commit('setMicrioList', micrios)
    },
    setCurrentMicrio(context, currentMicrio) {
      context.commit('setCurrentMicrio', currentMicrio)
    },
    setNextMicrio(context, nextMicrio) {
      context.commit('setNextMicrio', nextMicrio)
    },
    setShowMenu(context, showMenu) {
      context.commit('setShowMenu', showMenu)
    },
    setShowArrows(context, showArrows) {
      context.commit('setShowArrows', showArrows)
    },
    setMicrioTours(context, micrioTours) {
      context.commit('setMicrioTours', micrioTours)
    },
    setTourActive(context, tourActive) {
      context.commit('setTourActive', tourActive)
    },
    setShowStartOverlay(context, startOverlayActive) {
      context.commit('setShowStartOverlay', startOverlayActive)
    },
    setShowTourMenu(context, showTourMenu) {
      context.commit('setShowTourMenu', showTourMenu)
    }
    ,
    setShowTopMenu(context, showTopMenu) {
      context.commit('setShowTopMenu', showTopMenu)
    }
  },
  modules: {
  }
})
