<template>
    <div class="navigation-button" @click="openMenu()">
        <svg fill="none" height="428" viewBox="0 0 428 428" width="428" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <clipPath id="a">
                <path d="m0 0h427.143v427.143h-427.143z" />
            </clipPath>
            <g clip-path="url(#a)" fill="var(--svgcolor)" opacity=".95">
                <path
                    d="m320.357 320.357-48.367-74.136 155.153-32.65-155.153-32.649 48.367-74.136-74.136 48.367-32.65-155.153-32.649 155.153-74.136-48.367 48.367 74.136-155.153 32.649 155.153 32.65-48.367 74.136 74.136-48.367 32.649 155.153 32.65-155.153zm-106.786-71.775c-19.36 0-35.01-15.65-35.01-35.011 0-19.36 15.65-35.01 35.01-35.01 19.361 0 35.011 15.65 35.011 35.01 0 19.361-15.65 35.011-35.011 35.011z" />
                <path
                    d="m192.656 34.0663 2.9-13.8963c-92.889 8.5672-166.8228 82.501-175.39 175.39l13.8963-2.9c9.579-82.974 75.6197-149.0147 158.5937-158.5937z" />
                <path
                    d="m393.074 192.66 13.896 2.9c-8.567-92.889-82.501-166.8903-175.39-175.39l2.901 13.8963c82.973 9.579 149.014 75.6197 158.593 158.5937z" />
                <path
                    d="m34.0623 234.483-13.8963-2.9c8.5672 92.889 82.501 166.89 175.39 175.39l-2.9-13.897c-82.974-9.579-149.0147-75.62-158.5937-158.593z" />
                <path
                    d="m234.481 393.076-2.901 13.897c92.889-8.567 166.891-82.501 175.39-175.39l-13.896 2.9c-9.579 82.973-75.62 149.014-158.593 158.593z" />
                <circle cx="213.569" cy="213.571" r="16.0178" />
            </g>
        </svg>
    </div>
</template>

<script scoped>
export default {
    name: 'NavigationButton',
    methods: {
        openMenu() {
            this.$store.dispatch('setShowMenu', true)
            this.$store.dispatch('setShowArrows', false)
        }
    }
}

</script>

<style lang="scss">
.navigation-button {

    position: absolute;
    bottom: 32px;
    right: 32px;

    >svg {
        transition: transform 200ms ease-in;
        height: 100px;
        width: 100px;
    }

    >g {
        transition: fill 100ms ease-in;
    }

    &:hover {
        --svgcolor: var(--color-primary);
        cursor: pointer;

        >svg {
            transform: rotate(90deg);
        }
    }
}
</style>