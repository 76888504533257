<template>
  <div id="app">
    <nav v-show="!$store.getters.showMenu && $store.getters.showArrows && !$store.getters.tourActive">
      <transition-group name="fade">
        <router-link v-for="connection in $route.meta.connections" :key="connection.page"
          :class="`arrow ${connection.arrow}`" :to="connection.page">
          <img class="arrow-image" :src="`${connection.arrow}.svg`" />
        </router-link>
      </transition-group>
    </nav>

    <TopNavigation></TopNavigation>

    <transition :key="$route.path" name="fade" mode="out-in">
      <!-- <keep-alive> -->
      <router-view v-slot="{ Component }" :key="$route.path">
        <component :is="Component" v-cloak />
      </router-view>
      <!-- </keep-alive> -->
    </transition>


    <transition name="fade">
      <NavigationOverlay key="navigation-overlay" v-if="$store.getters.showMenu"></NavigationOverlay>
      <NavigationButton key="navigation-button" v-if="!$store.getters.showMenu"></NavigationButton>
    </transition>
    <transition name="fade">
      <StartOverlay v-if="showOverlay" key="start-overlay"></StartOverlay>
    </transition>

  </div>
</template>

<script>
import NavigationOverlay from '@/components/NavigationOverlay.vue'
import StartOverlay from '@/components/StartOverlay.vue'

import NavigationButton from '@/components/NavigationButton.vue'
import TopNavigation from '@/components/TopNavigation.vue'
import MicrioComponent from '@/components/MicrioComponent.vue'
export default {
  components: {
    NavigationOverlay,
    NavigationButton,
    TopNavigation,
    StartOverlay,
    "MicrioComponent": MicrioComponent,
  },
  data() {
    return {
      transitionName: '',
      micrioObj: null,
      micrioLoaded: false,
      showNavigationOverlay: true,
      currentMicrio: null,
      keys: 1,
    }
  },
  created() {

  },
  methods: {
    openMenu() {
      this.$store.dispatch('setShowMenu', true)
    }

  },
  mounted() {
    const id = 'micrioScript'
    if (document.getElementById(id) === null) {
      const micrioScript = document.createElement('script')
      micrioScript.setAttribute('id', id)
      micrioScript.setAttribute('src', 'https://b.micr.io/micrio-4.1.min.js')
      document.head.appendChild(micrioScript)
    }

  },
  computed: {
    showOverlay() {
      return this.$store.getters.startOverlayActive && !sessionStorage.getItem('startCompleted') && this.$route.name === 'center'
    },
    routeKey() {
      const helper = this.$route.matched[0].meta.key
      console.log(this.$route)
      return helper ? helper(this.$route) : undefined
    }, component() {
      return MicrioComponent
    }
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch('setShowMenu', false)
      this.$store.dispatch('setShowTopMenu', false)
      this.$store.dispatch('setShowTourMenu', false)


      const contections = from.meta.connections?.filter((e) => {
        return e.name === to.name
      })
      if (contections?.length > 0) {
        // console.log(this.micrioService.getMicrioElById(to.meta.micrioId))
        this.transitionName = contections[0].transition
        this.$refs[to.name]
        this.currentMicrio = this.$refs[to.name]
        // this.micrioObj = this.micrioService.getMicrioElById(to.meta.micrioId)
      }
      else {
        // this.micrioObj = this.micrioService.getMicrioElById(to.meta.micrioId)
        this.transitionName = 'fade'
      }

      console.log('micrio obj', this.transitionName)
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+Thai&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

:root,
.micrio-interface,
.micrio-markers main {
  --color-primary: rgba(235, 119, 72, 0.9);
  --svgcolor: rgba(255, 255, 255, 0.8);
  --micrio-color-hover: var(--color-primary);
  --micrio-marker-color-hover: rgba(255, 255, 255, 0.6);
  --micrio-background-hover: rgba(255, 255, 255, 0.6);
  --micrio-marker-shadow: 0px 2px 8px 0px rgba(21, 63, 103, 0.2);
  --micrio-marker-border: 4px solid var(--color-primary);
  --micrio-marker-color: var(--color-primary) !important;
  --micrio-marker-border: 4px solid transparent;
  --micrio-marker-icon: url('../public/plus-white.svg');
}

.hidden {
  top: 100vh;
}

.thai {
  font-family: Noto Sans Thai;
  font-size: 21px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
}

.slide-left-enter {
  transform: translateX(100%);
}

.slide-right-enter {
  transform: translateX(-100%);
}

.slide-left-enter-active,
.slide-right-enter-active {
  transition: all 0.9s ease-in;
}


.slide-left-leave-active {
  transform: translateX(-90%);
  transition: all 0.9s ease-in;
}

.slide-right-leave-active {
  transform: translateX(90%);
  transition: all 0.9s ease-in;
}

.slide-up-enter {
  transform: translateY(100%);
}

.slide-down-enter {
  transform: translateY(-100%);
}

.slide-up-enter-active,
.slide-down-enter-active {
  transition: all 0.5s ease-in;
}


.slide-up-leave-active {
  transform: translateY(-90%);
  transition: all 0.9s ease-in;

}

.slide-down-leave-active {
  transform: translateY(92%);
  transition: all 0.9s ease-in;
}

#app,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: calc(100% - env(safe-area-inset-bottom));
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}

* {
  font-family: noto sans, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

nav {

  a {
    font-weight: bold;
    color: white;
  }

  .arrow {
    background-repeat: no-repeat;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 8px;
    position: absolute;
    z-index: 100;
    transition: width 200ms;


    &:hover {
      color: grey;
      transition: color 200ms;
    }
  }

  .arrow-image {
    height: 48px;
    width: 48px;
  }

  .left {
    top: 50%;
    transform: translateY(calc(-50% + 2px));
    left: 0;
  }

  .right {
    top: 50%;
    transform: translateY(calc(-50% + 2px));
    right: 0px;
  }

  .up {
    top: 56px;
    left: 50%;
    transform: translateX(-50%);
  }

  .down {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
  }
}

.debug-screen {
  z-index: 1000000;

}


.debug-screen-vertical {
  width: 1px;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
}

.debug-screen-horizontal {
  background-color: red;
  width: 100vw;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
}
</style>