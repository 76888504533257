<template>
    <div class="navigation-overlay">
        <button class="close-button" @click="closeMenu">
            <img src="plus-blue.svg" />
        </button>
        <CompassRose :top-left="topLeft" :top-right="topRight" :bottom-left="bottomLeft" :bottom-right="bottomRight"
            :rotation="angleDeg" :center="center">
            <NavigationLink :click="closeMenu" :mouseEnter="() => { setActiveArc(all) }"
                :mouseLeave="() => { setActiveArc(none); }" label="STORIES OF TRADE" dir="center" to='/' />
        </CompassRose>
        <NavigationLink :click="closeMenu" :mouseEnter="() => { setActiveArc(north) }"
            :mouseLeave="() => { setActiveArc(none) }" label="PEOPLE" dir="north" to="north" />
        <NavigationLink :click="closeMenu" :mouseEnter="() => { setActiveArc(east) }"
            :mouseLeave="() => { setActiveArc(none) }" label="BAAN HOLLANDA" dir="east" to="east" />

        <NavigationLink :click="closeMenu" :mouseEnter="() => { }" extraClass="hide-lg" :mouseLeave="() => { }"
            label="STORIES OF TRADE" dir="center" to='/' />

        <NavigationLink :click="closeMenu" :mouseEnter="() => { setActiveArc(west) }"
            :mouseLeave="() => { setActiveArc(none) }" label="CORRESPONDENCE" dir="west" to='west' />

        <NavigationLink :click="closeMenu" :mouseEnter="() => { setActiveArc(south) }"
            :mouseLeave="() => { setActiveArc(none) }" label="GOODS" dir="south" to='south' />

        <div class="navigation-overlay__bottom">
            <a class="navigation-overlay__bottom-link" target="_blank" href="https://baanhollanda.org/our-story/">OUR
                STORY</a>
            <a class="navigation-overlay__bottom-link" href="https://baanhollanda.org/get-involved/" target="_blank">GET
                INVOLVED</a>
            <a class="navigation-overlay__bottom-link" href="https://baanhollanda.org/plan-your-visit/" target="_blank">PLAN
                YOUR VISIT</a>
            <router-link to="about" class="navigation-overlay__bottom-link">
                DUTCH TRADING POST HERITAGE NETWORK
            </router-link>

        </div>
    </div>
</template>

<script>
import CompassRose from '@/components/CompassRose.vue'
import NavigationLink from './NavigationLink.vue'
export default {
    name: 'NavigationOverlay',
    data() {
        return {
            north: {
                topRight: true,
                bottomRight: true,
                pos: { x: 0, y: 1 }
            },
            east: {
                topRight: true,
                topLeft: false,
                bottomRight: true,
                bottomLeft: false,
                center: false,
                pos: { x: 1, y: 0 }
            },
            west: {
                topRight: true,
                topLeft: false,
                bottomRight: true,
                bottomLeft: false,
                center: false,
                pos: { x: -1, y: 0 }
            },
            south: {
                topRight: true,
                topLeft: false,
                bottomRight: true,
                bottomLeft: false,
                center: false,
                pos: { x: 0, y: -1 }
            },
            all: {
                topRight: true,
                topLeft: true,
                bottomRight: true,
                bottomLeft: true,
                center: true,
                pos: { x: 0, y: 1 }
            },
            none: {
                topRight: false,
                topLeft: false,
                bottomRight: false,
                bottomLeft: false,
                center: false,
                pos: false
            },

            topLeft: false,
            topRight: false,
            bottomLeft: false,
            bottomRight: false,
            center: false,
            rotation: { x: 0, y: 1 },
            lastRotation: { x: 0, y: 1 },
            angleDeg: 270
        }
    },
    components: {
        CompassRose,
        NavigationLink
    },
    methods: {
        closeMenu() {
            this.$store.dispatch('setShowMenu', false)
            this.$store.dispatch('setShowArrows', true)
        },
        calcAngle(p, sp, mp = { x: 0, y: 0 }) {
            var angle = 0;
            var sAngle = Math.atan2((sp.y - mp.y), (sp.x - mp.x));
            var pAngle = Math.atan2((p.y - mp.y), (p.x - mp.x));
            console.log(sAngle, pAngle)
            angle = (pAngle - sAngle) * 180 / Math.PI;
            return angle
        },

        setActiveArc(direction = false) {
            if (!direction) return;
            this.topLeft = direction.topLeft || false;
            this.topRight = direction.topRight || false;
            this.bottomLeft = direction.bottomLeft || false;
            this.bottomRight = direction.bottomRight || false;
            this.center = direction.center || false
            const newRotation = direction.pos || false

            if (newRotation) {
                let delta = this.calcAngle(this.rotation, newRotation)
                if (delta !== 0) {
                    if (delta === 270) {
                        delta = -90
                    } else if (delta === -270) {
                        delta = 90
                    }
                    this.angleDeg += delta
                    this.rotation = newRotation;
                }
            }
        }
    }
}

</script>

<style lang="scss" scoped>
.navigation-overlay {
    // background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: var(--color-primary);
        z-index: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 40px;
        box-sizing: border-box;
        animation: fadeIn 400ms ease-in-out;
    }

    @media only screen and (max-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

}

.navigation-overlay__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #F5F3EBE5;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    box-sizing: border-box;
    border-top: 1px solid var(--color-secondary);

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.navigation-overlay__bottom-link {
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    text-decoration: none;
    margin-right: 32px;
    color: #11111199;

    &:hover {
        color: #153F67;
    }
}


.compass-rose {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.close-button {
    position: absolute;
    top: 80px;
    right: 80px;
    border: none;
    background-color: transparent;
    width: 50px;
    height: 50px;

    @media only screen and (max-width: 600px) {
        top: 32px;
        right: 32px;
    }

    >img {
        height: 100%;
        width: 100%;
        transform: rotate(45deg);
    }

    &:hover {
        cursor: pointer;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>