<template>
    <div class="start-overlay">
        <div class="start-inner">
            <button @click="closeOverlay" class="close-button">
                <img src="plus-blue.svg" />
            </button>
            <div class="row titles-row">
                <div class="title-image">
                    <img src="Subtract.svg" />
                </div>
                <div class="titles">
                    <h2 class="title title-thai">เรื่องราวของการค้า</h2>
                    <h2 class="title">Stories of Trade</h2>
                </div>
            </div>
            <div class="content-row">
                <p>
                    Baan Hollanda is an information centre on the history
                    between Thailand and the Netherlands. It
                    relates the story of how the Dutch came to
                    Ayutthaya, how they traded and how they lived.
                    Baan Hollanda is situated on the land where once
                    there was a “factory” or trading post of the Dutch
                    United East India Company (Verenigde
                    Oost-Indische Compagnie or VOC), as well as a
                    settlement comprising both Dutch and people of
                    other nations. The city of Ayutthaya was then one of
                    the largest and busiest emporia in Southeast Asia,
                    filled with people of various ethnic origins, many of
                    whom had come to stay.
                </p>
            </div>
            <div class="actions-row">
                <div class="buttons">
                    <button class="button button--primary" @click="openTour">Start the tour</button>
                    <button class="button button--secondary" @click="closeOverlay">Start exploring</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StartOverlay',
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        closeOverlay() {
            this.$store.dispatch('setShowStartOverlay', false)
            sessionStorage.setItem("startCompleted", "true")
        },
        openTour() {
            if (this.$store.getters.currentMicrio) {
                const currentMicrio = this.$store.getters.currentMicrio
                currentMicrio.state.tour.set(currentMicrio.$current.$data.markerTours[0])
                // this.toggleDropdown()
                this.closeOverlay()
                this.$store.dispatch('setShowArrows', false)
                sessionStorage.setItem("startCompleted", "true")
            }
        },
    }
}

</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+Thai&display=swap');

.start-overlay {
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;

    @media only screen and (max-width: 600px) {
        height: 100%;
    }
}

.start-inner {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 490px;
    width: 480px;
    background-color: #F5F3EB;
    border-radius: 3px;
    padding: 32px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        height: 100%;
    }
}

.content-row {
    margin-bottom: 32px;
    text-align: left;

    @media only screen and (max-width: 600px) {
        font-size: 15px;
    }

    color: #111111;

    font-family: 'Noto Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.17px;
}

.actions-row {
    display: flex;
    justify-content: space-between;
}

.titles-row {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.button {
    border: none;
    min-width: 149px;
    height: 47px;
    margin-right: 12px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    border-radius: 3px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 12px;
    }

    &:hover {
        cursor: pointer;
    }

    /* White */
    &--primary {
        transition: color 200ms ease-in;
        background-color: var(--color-primary);
        color: #FFFFFF;

        &:hover {
            color: #111111;
        }
    }

    &--secondary {
        background-color: #FFFFFF;
        color: var(--color-black);
        transition: background-color 200ms ease-in;
        border-radius: 3px;

        &:hover {
            color: #FFFFFF;
            background-color: var(--color-primary);
        }
    }

    &--link {
        min-width: unset;
        background-color: transparent;
        text-decoration: underline;
        line-height: 26px;
        /* identical to box height */
        text-align: right;
        letter-spacing: 0.01em;
    }
}

.title {
    margin: 0;
    font-size: 28px;
    color: #111111;

    @media only screen and (max-width: 600px) {
        font-size: 24px;
    }

}

.titles {}

.title-image {
    margin-right: 18px;
}

.close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    border: none;
    background-color: transparent;

    >img {
        transform: rotate(45deg);
    }

    &:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>