<template>
    <div>
        <div ref="wrapper" class="wrapper">
        </div>
        <div>
            <transition :key="$route.fullPath" name="open" mode="" enterActiveClass="open-enter-active">
                <router-view :key="routeKey"></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MicrioComponent',
    props: ['id', 'meta', 'col'],
    data() {
        return {
            init: false,
            micrioEl: null
        }
    },
    computed: {
        routeKey() {
            const helper = this.$route.matched[0].meta.key
            return helper ? helper(this.$route) : undefined
        }
    },
    destroyed() {
    },

    mounted() {
        // console.log(this.$route)
        const container = this.$refs.wrapper;
        container.style.backgroundColor = this.color

        this.micrioEl = document.createElement('micr-io')
        this.micrioEl.setAttribute('social', false)
        this.micrioEl.setAttribute('minimap', false)
        this.micrioEl.setAttribute('inittype', 'cover')
        this.micrioEl.setAttribute('logo', 'false')
        this.micrioEl.setAttribute('lazyLoad', false)
        this.micrioEl.setAttribute('resetView', false)
        this.micrioEl.setAttribute('keepRendering', true)


        this.micrioEl.setAttribute('id', this.$route.meta.micrioId)
        container.appendChild(this.micrioEl)

        this.micrioEl.addEventListener('show', () => {
            // this.micrioEl.events.unhook()
            this.init = true;
            this.$store.dispatch('setCurrentMicrio', this.micrioEl)
            this.$store.dispatch('setMicrioTours', this.micrioEl.$current.$data.markerTours)

            this.micrioEl.addEventListener('tour-start', () => {
                this.$store.dispatch('setTourActive', true)
            })

            this.micrioEl.addEventListener('tour-stop', () => {
                this.$store.dispatch('setTourActive', false)
                this.$store.dispatch('setArrowsActive', true)

            })
        })

        this.micrioEl.addEventListener('marker-closed', () => {
            this.$store.dispatch('setShowArrows', true)

        })

        this.micrioEl.addEventListener('resize', e => {
            console.log('Marker was opened!', e.detail);
            e.preventDefault()
        });

        this.micrioEl.addEventListener('marker-opened', (evt) => {

            // ugly but interval seems to be the only way for now
            // there is no other way to know if the popup is available
            // because the "marker-opened" event only checks if the marker is ready
            let t = setInterval(() => {
                let marker = evt.detail;

                //hide standard close button
                let backButton = document.querySelectorAll('.close.micrio-action')[0];
                if (marker.class !== 'marker-country' && this.add_navigation === 'True') {
                    backButton.style.display = 'none'
                }


                let container = document.querySelectorAll('div.default.marker-popup')[0]
                if (container) {

                    this.$store.dispatch('setShowArrows', false)

                    clearInterval(t);

                    if (container.querySelectorAll('.popup-close').length > 0) {
                        return;
                    }

                    //create a container for our controls (flex)
                    const controlsContainer = document.createElement("div")
                    controlsContainer.className = "container-controls";
                    container.querySelector('main').appendChild(controlsContainer)
                    const markers = this.micrioEl.$current.$data.markers;
                    if (this.micrioEl.$current.$data.markers.length > 1 && !this.$store.getters.tourActive) {
                        let tourButtonsContainer = document.createElement("div");
                        tourButtonsContainer.classList.add('container-controls-inner')
                        tourButtonsContainer.appendChild(addButton("popup-prev", 'prev.svg', () => getPrevMarker(marker)));
                        tourButtonsContainer.appendChild(addButton("popup-next", 'next.svg', () => getNextMarker(marker)));
                        const text = `${markers.map(e => e.id).indexOf(marker.id) + 1}  / ${markers.length + 1} `;
                        const textEl = document.createElement('p')
                        textEl.innerHTML = text
                        controlsContainer.appendChild(textEl)
                        controlsContainer.appendChild(tourButtonsContainer);
                    }
                }
            }, 1);
        });

        const getNextMarker = (marker) => {
            let markers = this.micrioEl.$current.$data.markers;
            let markerIndex = markers.map(e => e.id).indexOf(marker.id);
            if (markerIndex + 1 < markers.length) {
                this.micrioEl.$current.state.marker.set(this.micrioEl.$current.$data.markers[markerIndex + 1])
            } else {
                this.micrioEl.$current.state.marker.set(this.micrioEl.$current.$data.markers[0])
            }
        }

        const getPrevMarker = (marker) => {
            let markers = this.micrioEl.$current.$data.markers;
            let markerIndex = markers.map(e => e.id).indexOf(marker.id);
            if (markerIndex - 1 >= 0) {
                this.micrioEl.$current.state.marker.set(this.micrioEl.$current.$data.markers[markerIndex - 1])
            } else {
                this.micrioEl.$current.state.marker.set(this.micrioEl.$current.$data.markers[markers.length - 1])
            }
        }

        const addButton = (c, i, f) => {
            let button = document.createElement("button");
            let buttonImg = document.createElement("img");
            buttonImg.setAttribute('src', i);
            button.appendChild(buttonImg);
            button.classList.add(c);
            button.onclick = f;

            return button;
        }
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+Thai&display=swap');

.wrapper {
    width: 100vw;
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    height: calc(100vh - 50px - var(--safe-area-inset-bottom));
    position: relative;
    padding-bottom: var(--safe-area-inset-bottom);
    // z-index: 3;
}

micr-io {
    width: 100vw;

    .burger {
        display: none;
    }
}

aside {
    &.controls {
        top: 20%;
        bottom: unset;
        right: 0;
        background-color: rgba(17, 17, 17, 0.9);
    }
}

button {
    &.micrio-action {
        &:hover {
            background-color: rgba(17, 17, 17, 0.9);
        }
    }
}

.popup-prev,
.popup-next {
    background-color: transparent;
    position: relative;
    border: none;
    outline: none;
    height: 100%;
    width: 20px;
    display: flex;
    justify-content: center;
    padding: 8px 12px;
    cursor: pointer;

    transition: background-color 200ms ease-in;

    &:hover {
        background-color: var(--color-primary);
    }
}

.container-controls-inner {
    display: flex;
}

.container-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >p {

        font-weight: 600;
        font-size: 17px;
        line-height: 23.15px;
    }
}


.marker {
    opacity: 1;

    &.opened,
    &:hover {
        --micrio-marker-icon: url('../../public/plus-blue.svg');
    }
}

menu.toolbar {
    display: none;
}

.tour {
    background-color: rgba(17, 17, 17, 0.9);
}

.marker-popup {
    >button {
        &.micrio-action {
            top: 24px !important;
            right: 24px !important;
        }
    }

    >main {
        background-color: rgba(17, 17, 17, 0.9);
        border-radius: 4px;
        padding: 24px 24px 24px;
        text-align: left;
        font-family: Noto Sans;
        border: none;

        @media only screen and (max-width: 600px) {
            height: 100%;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.01em;
        }

        >h3 {
            font-size: 17px;
            font-weight: 600;
            line-height: 23px;
            letter-spacing: 0em;

            &.marker-popup {
                margin-left: 0
            }
        }

        >div {
            &.body {
                &.marker-popup {
                    margin-left: 0;
                    max-height: unset;

                    >p {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.open-enter-active,
.open-leave-active {
    transition: opacity 1000ms;
    left: 500px;
}

.open-enter,
.open-leave-to {
    opacity: 0.1;
}
</style>