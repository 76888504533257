<template>
    <router-link :class="`nav-link ${dir} ${extraClass}`" :to="to">
        <div @click="click()" @mouseenter="mouseEnter()" @mouseleave="mouseLeave">
            {{ label }}
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'NaviagionLink',
    props: ['click', 'extraClass', 'mouseEnter', 'mouseLeave', 'label', 'dir', 'to'],
}
</script>


<style scoped lang="scss">
.nav-link {
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    position: relative;

    >div {
        font-family: Noto Sans;
        font-weight: 600;
        letter-spacing: 0em;
    }


    @media only screen and (max-width: 600px) {
        font-size: 32px;
        line-height: 96px;
    }

    @media only screen and (min-width: 600px) {
        position: absolute;
        left: 50%;
        top: 50%;

        >div {
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0em;
            line-height: 33px;

        }
    }

    &:hover {
        color: #153F67;
        text-decoration: underline;
        text-underline-offset: 4px;
    }

    animation-iteration-count: 1;
    animation: fadeIn 4s forwards;
}

@media only screen and (min-width: 600px) {

    .hide-lg {
        display: none;
    }

    .east {
        transform: translate(250px, -50%)
    }

    .west {
        transform: translate(-460px, -50%)
    }

    .north {
        transform: translate(-50%, -400px);

        >div {
            height: 160px;
            align-items: flex-end;
        }
    }

    .south {
        transform: translate(-50%, 400px);
        align-items: flex-start;
        top: unset;
        bottom: 440px;
    }

    .center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }
}
</style>